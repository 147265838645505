import React, {Component} from "react";
import { HydraAdmin, ResourceGuesser, ListGuesser, FieldGuesser, ShowGuesser} from "@api-platform/admin";
import { dataProvider as baseDataProvider, fetchHydra as baseFetchHydra  } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authProvider from './authProvider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { showNotification, ImageField, translate, SimpleForm, FileInput, SaveButton, FileField } from 'react-admin';
import { CreateButton, RefreshButton} from 'admin-on-rest';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Pagination} from 'react-admin';
import params from "admin-on-rest/src/reducer/admin/resource/list/params";
require('dotenv').config();

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
// Listes et informations affichées
const OfferList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="name" />
            <FieldGuesser source="uniqueName" />
            <FieldGuesser source="provider"/>
            <FieldGuesser source="numberOfResources"/>
    </ListGuesser>
);

const AlertList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="name" />
            <FieldGuesser source="identifier" />
            <FieldGuesser source="subject"/>
            <FieldGuesser source="preview"/>
            <FieldGuesser source="type"/>
    </ListGuesser>
);
const ClientList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="name" />
            <FieldGuesser source="identifier" />
            <FieldGuesser source="type"/>
            <FieldGuesser source="uai"/>
            <FieldGuesser source="email"/>
            <FieldGuesser source="origin" addLabel={true} chiplabel="name"/>
            <FieldGuesser source="createdAt"/>
            <FieldGuesser source="updatedAt"/>
    </ListGuesser>
);
const OrderList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="identifier"/>
            <FieldGuesser source="email"/>
            <FieldGuesser source="amount"/>
            <FieldGuesser source="paid"/>
            <FieldGuesser source="duration"/>
            <FieldGuesser source="createdAt"/>
            <FieldGuesser source="updatedAt"/>
    </ListGuesser>
);

const SubscriptionList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="offer"/>
            <FieldGuesser source="begin"/>
            <FieldGuesser source="end"/>
            <FieldGuesser source="client"/>
            <FieldGuesser source="delivered"/>
            <FieldGuesser source="ended"/>
            <FieldGuesser source="order"/>
    </ListGuesser>
);

const ResourceList = props => (
    <ListGuesser actions={<ResourceListActions />}  {...props} perPage={100}  pagination={<PostPagination />}>
            <ImageField source="imageMediumUrl" width="80px"/>
            <FieldGuesser source="name"/>
            <FieldGuesser source="type"/>
            <FieldGuesser source="mimeType"/>
            <FieldGuesser source="provider"/>
            <FieldGuesser source="offer"/>
    </ListGuesser>
);
const ProviderList = props => (
    <ListGuesser {...props} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="name"/>
            <FieldGuesser source="description"/>
            <FieldGuesser source="authClientId"/>
            <FieldGuesser source="uniqueName"/>
            <FieldGuesser source="numberOfResources"/>
    </ListGuesser>
);
const ActionList = props => (
    <ListGuesser {...props} bulkActionButtons={false} perPage={100}  pagination={<PostPagination />}>
            <FieldGuesser source="object"/>
            <FieldGuesser source="name"/>
            <FieldGuesser source="serialisedObject"/>
    </ListGuesser>
);
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

class ImportResourceButton extends Component {
    handleClick1 = () => {
        const { push, record, showNotification, translate, basePath} = this.props;
        var object = document.getElementById('importFile');
        var file = object.files[0];
        fetch(process.env.REACT_APP_API_IMPORT_RESOURCES , { method: 'POST'})
            .then(() => {
                showNotification('Cache vidé');
            })
            .catch((e) => {
                showNotification('Erreur lors du vidage de cache', 'warning');

            });
    };

    render() {
        return <form onSubmit={this.handleClick1} >
            <input id="importFile"  type="file" label="Related files" accept=".csv,.txt" data-url={process.env.REACT_APP_API_IMPORT_RESOURCES}>
            </input>
            <SaveButton type="submit" showNotification={showNotification} translate={translate} >Import </SaveButton>
        </form>;
    }
}
const ResourceListActions = ({resource, filters, displayedFilters, filterValues, showFilter, basePath, data, translate }) => (
     <CardActions >
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
        <MuiThemeProvider>
        <CreateButton translate={translate}  label="Create" basePath={process.env.REACT_APP_BASE_PATH} width='100px'>Create</CreateButton>
        <SimpleForm basePath={process.env.REACT_APP_API_IMPORT_RESOURCES} save={function handleClick1() {
            fetch(basePath , { method: 'POST', body: JSON.stringify(params.data)})
                .then(() => {
                    showNotification('Cache vidé');
                })
                .catch((e) => {
                    showNotification('Erreur lors du vidage de cache', 'warning');

                });
        }} >
            <FileInput id="importFile"  type="file" label="Related files" accept=".csv,.txt" translate={translate}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
        </MuiThemeProvider>
    </CardActions>
);
ImportResourceButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    translate: PropTypes.func
};

class ResetButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        fetch( process.env.REACT_APP_API_CLEAR_CLIENT_CACHE+`${record.originId}`, { method: 'GET'})
            .then(() => {
                showNotification('Cache vidé');
                console.log(showNotification('Cache vidé'));
            })
            .catch((e) => {
                showNotification('Erreur lors du vidage de cache', 'warning');
                console.log(showNotification('Erreur lors du vidage de cache', 'warning'));
            });
    };
    render() {
        return <Button label="Reset Cache" onClick={this.handleClick} > Reset Cache</Button>;
    }
}
ResetButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

const PostShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        { <ResetButton record={data} showNotification={showNotification} />}
    </CardActions>
);

const ClientShow = props => (
    <ShowGuesser actions={<PostShowActions />} {...props} >

    </ShowGuesser>
);

const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});



//Sécurité : Vérifie si la personne est connectée sinon renvoie à l'écran de connexion
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={'/login'}/>,
                            },
                        }],
                    });
                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

// Default Exporter for api platform admin
export default () => (
    <HydraAdmin entrypoint="{process.env.REACT_APP_API_ENTRYPOINT}"  apiDocumentationParser={ apiDocumentationParser }
                dataProvider={ dataProvider }
                authProvider={ authProvider } showNotification={showNotification} translate={translate} >
        <ResourceGuesser name="alerts" options={{ label: 'Alertes' }} list={AlertList} />
        <ResourceGuesser name="clients" options={{ label: 'Clients' }} list={ClientList} show={ClientShow} />
        <ResourceGuesser name="offers" options={{ label: 'Offres' }} list={OfferList}  />
        <ResourceGuesser name="orders"  options={{ label: 'Commandes' }} list={OrderList}/>
        <ResourceGuesser name="origins"  options={{ label: 'Origines' }} />
        <ResourceGuesser name="providers"  options={{ label: 'Éditeurs' }} list={ProviderList}/>
        <ResourceGuesser name="resources"  options={{ label: 'Ressources' }} list={ResourceList}/>
        <ResourceGuesser name="subscriptions"  options={{ label: 'Abonnements' }} list={SubscriptionList} />

    </HydraAdmin>
);